:root {
  /*
  
  --sans-serif-font: "Inter", sans;
  */

  --sans-serif-font: "Inter", "Roboto", "Segoe UI", "Tahoma", "Verdana", sans-serif;

  --sans-font: "Source Serif Pro", "Garamond", "Georgia", sans;
  --font-family: var(--sans-serif-font);
  --font-family-error-code: "Press Start 2P";
  --shadow-xl: 0 8px 10px rgba(0 0 0 / 10%);
  --heeder-height: 64px;
}

html,
body {
  background-color: var(--color-bg);
  font-family: var(--font-family);
}

body * {
  font-family: var(--font-family) !important;
}

.design-system-container > div {
  padding: 20px 0;
}

.sans-serif-font {
  --font-family: var(--sans-serif-font);

  font-family: var(--font-family);
}

.sans-font {
  --font-family: var(--sans-font);

  font-family: var(--font-family);
}

.hight-light-text {
  background-color: #b5efc06e;
}

.mdxeditor-popup-container {
  z-index: 1000000;
}
